@import "variables";
// Include Bootstrap and BootstrapVue SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
@import "helpers";

#app {
    // font-family: "Bebas Neue", cursive;
    font-family: "Montserrat", sans-serif;
    transition: 0.4s;
    // font-size: 1rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 149px;
    right: 0;
    left: 0;
    bottom: 0;

    &:before {
        content: "";
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -10;
    }

    .navbar {
        @extend .fixed-top;
        background-color: white;
        padding: 1.5rem 3rem;
        transition: 0.4s;

        &.navbar-light .navbar-nav {
            .nav-link {
                color: $primary !important;
                // font-weight: 500;
            }

            .nav-item {
                margin: 0 1rem;
            }
        }

        .navbar-brand {
            transition: 0.4s;

            img {
                max-height: 100px;
                transition: 0.4s;
            }
        }

        #navbar-toggle-collapse {
            transition: 0.4s;
        }

        #nav_toggle {
            border-color: rgba($color: $primary, $alpha: 0.9);

            .navbar-toggler-icon {
                background-image: none;
                color: $secondary;
                display: flex !important;
                align-items: center;
                justify-content: center;
            }
            .navbar-toggler-icon::before {
                font-family: "Font Awesome 6 Free";
                font-weight: 900;
                content: "\f0c9"; /* fa-bars, fa-navicon */
            }
        }
    }

    @include media-breakpoint-down(sm) {
        top: 56px;

        .navbar {
            padding: 0.5rem 1rem;

            .navbar-brand {
                padding: 0;
                margin-top: -5px;
                margin-bottom: -5px;

                img {
                    max-height: 70px;
                    // max-width: 200px;
                }

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        &.shrink {
            top: 0;

            .navbar {
                padding: 0.5rem 1rem;
                background-color: rgba($color: $white, $alpha: 0.9) !important;

                .navbar-brand {
                    padding: 0;
                    margin-top: -5px;
                    margin-bottom: -5px;

                    img {
                        max-height: 50px;
                        // max-width: 200px;
                    }

                    @include media-breakpoint-down(md) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .accueil {
        min-height: 350px;
        height: 80vh;
        background-color: rgba($color: $primary, $alpha: 0.3) !important;

        @include media-breakpoint-down(sm) {
            height: 10vh;
            min-height: 250px;
        }

        .moving-bg {
            z-index: -1;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .home-title {
            letter-spacing: 3px;
            font-size: 7vw;
            color: white;
            text-shadow: 0.5px 0.5px $gray-600;
            font-weight: 400;
            font-family: "Bebas Neue", cursive;
            text-align: center;
            margin: 0;

            .le-2 {
                color: $orange;
            }

            @include media-breakpoint-down(sm) {
                font-size: 10vw;
                max-width: 90%;
            }
        }
    }

    .accueil-presentation {
        background-image: url(../img/meeting-room.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .presentation-content {
            max-width: 700px;
            background-color: rgba($color: $white, $alpha: 0.95);

            .presentation-title {
                color: $secondary;
                font-weight: 600;
            }

            .presentation-subtitle {
                color: $secondary;
                font-weight: 600;
            }

            .presentation-description {
                color: $primary;
                line-height: 1.75;
            }
        }
    }

    .service-section {
        .service-list {
            .service-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

                .service-item-content {
                    width: 100%;
                    height: 100%;
                    color: $white;
                    text-align: center;
                    background-color: rgba($color: $primary, $alpha: 0.3) !important;
                    padding: 5rem 0;

                    .service-item-title {
                        text-transform: uppercase;
                        margin-bottom: 1.5rem;
                        font-weight: 500;
                    }

                    .service-item-text {
                        // font-weight: 300;
                    }
                }

                &.formation {
                    background-image: url(../img/formation.jpeg);
                }

                &.conseil {
                    background-image: url(../img/conseil.jpeg);
                }

                &.accompagnement {
                    background-image: url(../img/accompagnement.jpeg);
                }
            }
        }
    }
}
