$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #20428d;
$orange: #ed6b1d;

$primary: $blue;
$secondary: $orange;
$ref-marine: #1c0d44;
$ref-dark: #232323;
$ref-light: #e8eaea;
$ref-yellow: #ffdc00;

$theme-colors: () !default;

$theme-colors: map-merge(
    (
        "normal": $gray-600,
        "black": $black,
        "white": $white,
        "gray-1": $gray-100,
        "gray-2": $gray-200,
        "gray-3": $gray-300,
        "gray-4": $gray-400,
        "gray-5": $gray-500,
        "gray-6": $gray-600,
        "gray-7": $gray-700,
        "gray-8": $gray-800,
        "gray-9": $gray-900,
        "gold": gold,
        "blue": $blue,
        "orange": $orange,
    ),
    $theme-colors
);

$btn-focus-box-shadow: none !important;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    "0--5": ($spacer * .125),
    1: ($spacer * .25),
    "1--5": ($spacer * .375),
    2: ($spacer * .5),
    "2--5": ($spacer * .75),
    3: $spacer,
    "3--5": ($spacer * 1.25),
    4: ($spacer * 1.5),
    "4--5": ($spacer * 2.25),
    5: ($spacer * 3),
    "5--5": ($spacer * 4.5),
    6: ($spacer * 6),
  ),
  $spacers
);