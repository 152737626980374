.text-muted {
    color: #ffffff !important;
}

.text-bold {
    font-weight: bold;
}

.text-300 {
    font-weight: 300;
}
.text-400 {
    font-weight: 400;
}
.text-500 {
    font-weight: 500;
}
.text-600 {
    font-weight: 600;
}

.cursor-pointer {
    cursor: pointer;
}

.d-flex-center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.fs-xs {
    font-size: 60%;
}

.fs-sm {
    font-size: 80%;
}

.fs1 {
    @include font-size($h1-font-size);
}
.fs2 {
    @include font-size($h2-font-size);
}
.fs3 {
    @include font-size($h3-font-size);
}
.fs4 {
    @include font-size($h4-font-size);
}
.fs5 {
    @include font-size($h5-font-size);
}
.fs6 {
    @include font-size($h6-font-size);
}
